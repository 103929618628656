.sorting-work {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.work-page {
  position: relative;
}

@media (max-width: 800px) {
  .sorting-work {
    flex-direction: column;
  }
}

.job-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  padding: 1.5rem;
}

.job {
  width: 350px;
}

.job-list p {
  font-family: "futura-pt", sans-serif;
  font-size: 1.2rem;
}
