.job-site .text-section {
  padding: 1rem;
}

.job-site .text-section p {
  max-width: 80%;
}

.job-site .job-header {
  background-color: var(--darkest-color);
  position: relative;
}
.job-header .back-link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.job-header .back-link a {
  color: var(--lightest-color);
}

.job-header .back-link svg {
  stroke: var(--lightest-color);
}
.job-header .back-link:hover a {
  color: var(--primary-color);
}

.job-header .back-link:hover svg {
  stroke: var(--primary-color);
}

.job-site .job-header .text-section h3 {
  color: var(--lightest-color);
  margin-bottom: 0;
}

.job-site .job-header .text-section p {
  color: var(--mid-tone-color);
}

.job-site .job-body {
  display: flex;

  justify-content: center;
  align-items: center;
}

.job-site .job-body .text-section {
  padding-top: 1rem;
}
.job-site .job-body > * {
  flex: 1 1 0px;
}
.job-body .carousel-image {
  width: 100%;
}
.job-site .job-body .carousel-image img {
  min-height: 600px;
  width: 100%;
  aspect-ratio: 16 / 9;
}

@media (max-width: 1100px) {
  .job-site .job-body {
    flex-direction: column-reverse;
  }

  .job-site .job-body .carousel {
    height: fit-content;
    width: 100%;
  }
}
