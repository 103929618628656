.crew-member-detailed .text-section {
  max-width: 800px;
  margin: auto;
  padding: 0.5rem;
}

.crew-member-detailed .social svg {
  fill: var(--darkest-color);
}
.crew-member-detailed .social svg:hover {
  fill: var(--primary-color);
}

.crew-member-detailed img {
  max-width: 200px;
  width: 60%;
  display: block;
  padding: 1rem;
  margin: auto;
  border-radius: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  filter: grayscale();
}

.crew-member-detailed .text-section p {
  padding-bottom: 1rem;
}
