header {
  display: unset;
  position: relative;
  z-index: 99;

  -webkit-overflow-scrolling: auto !important;
}

header .main-header {
  background-color: var(--darkest-color);
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  align-items: center;
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  border-bottom: 2px solid var(--darkest-color);
  z-index: 99;

  -webkit-overflow-scrolling: auto !important;
}

.banner {
  background-color: var(--primary-color);
  padding: 0.5rem;
  font-family: "futura-pt", sans-serif;
  color: var(--lightest-color);

  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner h3 {
  text-align: center;
}

.logo img {
  width: 300px;
}

@media (max-width: 500px) {
  .logo img {
    width: 200px;
  }
}

/* Navigation */

header nav ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 2.5rem;
  position: relative;

  justify-content: center;
}

nav .links {
  display: block;
  position: relative;
  z-index: 99;
}

header .nav-link a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: var(--secondary-color);
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}
header .nav-link a:hover::before {
  transform: scaleX(1);
}

header .nav-link a {
  font-family: "futura-pt-condensed", sans-serif;
  /* font-family: Inter, sans-serif; */
  text-decoration: none;
  color: var(--lightest-color);
  font-size: 1.8rem;
  position: relative;
  font-weight: 500;
}

header .nav-link.selected a {
  font-weight: 600;
}

header .hamburger {
  display: none;
  width: 70px;
  cursor: pointer;
}
header .hamburger svg {
  fill: var(--lightest-color);
  stroke: var(--lightest-color);
}

@media (max-width: 1100px) {
  header .hamburger {
    display: block;
  }
  header nav .links {
    position: absolute;
    background-color: var(--darkest-color);
    left: 0;
    width: 100%;
    border-bottom: 2px solid var(--darkest-color);
  }
  header nav ul {
    display: flex;

    flex-wrap: wrap;
    gap: 0.2rem;
    padding: 1rem;
  }
  header nav li {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 0.2rem;
    flex-basis: 30%;
  }
}

@media (max-width: 800px) {
  header nav li {
    flex-basis: 40%;
  }
}
