footer {
  background-color: var(--darkest-color);
}

.copyright {
  color: var(--mid-tone-color);
  font-family: "futura-pt", sans-serif;
  text-align: center;
  padding: 0.5rem;
}

/* navigation */

footer nav ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 2.5rem;
  position: relative;

  justify-content: center;
  padding: 1rem;
}

footer .nav-link a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: var(--secondary-color);
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}
footer .nav-link a:hover::before {
  transform: scaleX(1);
}

footer .nav-link a {
  font-family: "futura-pt-condensed", sans-serif;
  text-decoration: none;
  color: var(--lightest-color);
  font-size: 1.8rem;
  position: relative;
  font-weight: 500;
}

footer nav .links {
  display: block;
}

@media (max-width: 800px) {
  footer nav ul {
    flex-direction: column;
  }
}
