.job {
  position: relative;
  transition: transform 0.5s;
}

.job:hover {
  transform: scale(1.1);
}

.job img {
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 0.2rem;
}

.job-information {
  border-radius: 0.2rem;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
}

.job-information .text-section {
  padding: 0.5rem;
  background-color: var(--darkest-color-transparent);
  border-radius: 0.2rem;
}

.job-information .text-section h3 {
  color: var(--lightest-color);
  font-size: 1.4rem;
  margin: 0;
}

.job-information .text-section p {
  color: var(--mid-tone-color);
  font-size: 1.1rem;
}

.job-information .text-section h3,
.job-information .text-section p {
  text-align: left;
}

.job-tags {
  display: flex;
  flex-wrap: wrap;

  text-align: right;
  gap: 0.25rem;

  align-items: flex-end;
  padding: 0.25rem;
}

.job-tag {
  font-family: "futura-pt-condensed", sans-serif;
  color: var(--secondary-color);
  background-color: var(--darkest-color);

  width: fit-content;
  border-radius: 1rem;

  padding: 0.1rem 0.35rem;
}

.job-tag p {
  font-size: 1rem;
}
