.crew {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1rem;
  padding: 2rem;
}

.crew .crew-member {
  width: 300px;
}

.crew-section {
  position: relative;
}
