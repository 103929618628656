.back-link {
  cursor: pointer;
  width: fit-content;
}

.back-link svg {
  height: 1.2rem;
  display: block;
  stroke: var(--darkest-color);
}

.back-link a {
  padding: 1rem;
  color: var(--darkest-color);
  font-family: "futura-pt", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  display: flex;

  align-items: center;
}

.back-link:hover > a {
  color: var(--primary-color);
}

.back-link:hover svg {
  stroke: var(--primary-color);
}
