div.text-section {
  padding: 2rem;
  color: var(--darkest-color);
}

.text-section h3 {
  font-family: "futura-pt", sans-serif;
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
  text-align: center;
}

.text-section p {
  font-family: "futura-pt", sans-serif;
  font-size: 1.3rem;
  line-height: 140%;
  margin: auto;
  text-align: center;
}

.text-section p ~ p {
  margin: 1rem auto;
}
.text-section .image-container {
  max-height: 50vh;
  aspect-ratio: 16 / 9;
  margin: auto;
  padding: 1rem;
}
.text-section img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
