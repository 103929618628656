.map .button {
  position: relative;
  z-index: 5;
  margin: 0.5rem;
}

.map .button a {
  font-size: 1.5rem;
}

.contact-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  align-items: center;
  position: relative;
}

.contact-section .contact-info {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  gap: 2rem;
}

@media (max-width: 1100px) {
  .contact-container {
    grid-template-columns: 1fr;
  }
  .contact-section {
    padding: 0 2rem;
    gap: 1rem;
  }
  .contact-info {
    flex-direction: column;
  }
  .contact-form {
    padding-top: 1rem;
  }
}

@media (max-width: 500px) {
  .contact-section .contact-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 0rem !important;
  }
}

.contact-section {
  margin: 5rem 0;
}

.contact-section .text-section h3,
.contact-section .text-section p {
  text-align: left;
}

.contact-section .text-section {
  padding: 0;
}
.contact-section > .text-section {
  margin-top: 1rem;
}
.contact-section > .text-section h3 {
  font-size: 1.5rem;
}

.contact-section h3 {
  font-size: 1.2rem;
  padding: 0;
}

.contact-person p {
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
}

.contact-section .contact-person .text-section {
  padding: 0;
}

.contact-section a {
  color: var(--darkest-color);
  position: relative;
  padding: 0.1rem;
  text-align: center;
  font-family: "futura-pt", sans-serif;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.contact-section a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: var(--secondary-color);
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.contact-section a:hover::before {
  transform: scaleX(1);
}
