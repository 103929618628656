.carousel {
  position: relative;
}
.carousel .images {
  width: 100%;
  position: relative;
}

.carousel-image {
  display: none;

  width: 100%;
}
@keyframes fade-out {
  0% {
    opacity: 1;
    display: block;
  }

  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fade-in {
  0% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.carousel-image:not(.selected) {
  animation-name: fade-out;

  animation-duration: 0.5s;
}

.current.carousel-image {
  display: block;
  animation-name: fade-in;

  animation-duration: 0.5s;
}

.carousel-image img {
  display: block;
  width: 100%;
  border-radius: 0.2rem;
  object-fit: cover;
}

.indicators {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  max-width: 90%;
  width: max-content;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
  flex-wrap: wrap;
  background-color: var(--darkest-color-transparent);
  padding: 1rem;
  border-radius: 0.2rem;
}

.indicator {
  background-color: var(--lightest-color);
  padding: 0.75rem;
  border-radius: 0.1rem;
}

.indicator.current {
  background-color: var(--secondary-color);
}

.arrows.lower {
  top: 78%;
}

.arrows {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  justify-content: space-between;
  width: 95%;
}

.arrows .previous,
.arrows .next {
  background-color: var(--darkest-color-transparent);
  padding: 0.5rem;
  border-radius: 0.2rem;
}

.arrows svg {
  width: 40px;
  fill: var(--lightest-color);
  stroke: var(--lightest-color);
  cursor: pointer;
  stroke-width: 2.8;
  transition: all 0.2s;
  transform: translateY(0.1rem);
}

.arrows svg:hover {
  stroke: var(--secondary-color);
  fill: var(--secondary-color);
}

@media (max-width: 600px) {
  .arrows.lower {
    top: 90%;
  }
  .indicators {
    display: none;
  }
}
