.grid-links {
  display: flex;
  gap: 2rem;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.grid-link {
  position: relative;
  width: 225px;
  transition: transform 0.5s;
}

.grid-link img {
  aspect-ratio: 1 / 1;
  width: 100%;
  display: block;
  object-fit: cover;

  border-radius: 0.2rem;
}

.grid-link h4 {
  color: var(--lightest-color);
  padding: 0.8rem;
  background-color: var(--darkest-color-transparent);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 0.2rem;
  text-align: center;
  font-family: "futura-pt-condensed", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  z-index: 1;
}

a {
  text-decoration: none;
}

.grid-link:hover {
  transform: scale(1.2);
}
