form {
  margin: auto;
  width: 90%;
}
.contact-form .text-section {
  padding-top: 0;
}
input,
textarea {
  display: block;
  font-family: "futura-pt", sans-serif;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
  margin-bottom: 1rem;
  border: none;
  background-color: var(--lightest-color);
  color: var(--darkest-color);
  border-bottom: 2px solid var(--darkest-color);
}
input:focus,
textarea:focus {
  outline: none;
}

textarea {
  height: 100px;
  resize: none;
}

label {
  font-family: "futura-pt", sans-serif;
  display: block;
  margin-bottom: 0.2rem;
  font-size: 1.2rem;
}

form .button {
  margin: auto;
  width: 100%;
  /* font-family: "futura-pt-condensed", sans-serif;
  font-size: 1.5rem;
  font-weight: 600; */
}
