.home-page-carousel {
  position: relative;
}

.home-page-carousel .carousel-image img {
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 300px;
  object-fit: cover;
}

.floating-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: var(--darkest-color-transparent);
  padding: 2rem;
  border-radius: 0.2rem;
  width: 50%;
}

.floating-box h1 {
  font-size: 3.5rem;
  color: var(--lightest-color);
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: 0.5rem;
}

@media (max-width: 800px), (max-height: 600px) {
  .floating-box {
    width: 100%;
  }
}

.about-us {
  background-color: var(--darkest-color);
  font-style: italic;
}

.about-us .text-section h3 {
  color: var(--lightest-color);
}
.about-us .text-section p {
  font-size: 1.5rem;
}

.about-us p {
  color: var(--mid-tone-color);
  width: 80%;
}

@media (max-width: 500px) {
  .about-us p {
    width: 90%;
  }
}

.what-we-do {
  padding: 2rem;
  position: relative;
}
