.button,
.button button {
  background: var(--primary-color);
  position: relative;
  z-index: 1;
  border-radius: 0.2rem;
  transition: all 0.3s ease-in-out;

  border: none;
  width: fit-content;

  /* outline: 0px solid #ffffff00;
  outline-offset: -2.2rem;

  transition: outline 0.5s; */

  cursor: pointer;
}

.button button {
  width: 100%;
}

.button a,
.button span {
  text-decoration: none;
  font-family: "futura-pt-condensed", sans-serif;
  color: var(--lightest-color);
  font-size: 2rem;
  padding: 0.75rem 1.5rem;
  display: block;
  z-index: 1;
  position: relative;
  font-weight: 500;
  text-align: center;
}

@media (pointer: fine) and (any-hover: hover) {
  .button::before,
  .button button::before {
    background: var(--darkest-color);
    background: radial-gradient(circle at top left, var(--darkest-color) 55%, #ffffff00 56%);

    content: "";
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;

    transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
  }
  .button:hover:before,
  .button button:hover:before {
    height: 100%;
    width: 100%;
  }
  .button::after,
  .button button::after {
    background: var(--darkest-color);
    background: radial-gradient(circle at bottom right, var(--darkest-color) 55%, #ffffff00 56%);

    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;

    transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
  }
  .button:hover:after,
  .button button:hover:after {
    height: 100%;
    width: 100%;
  }

  .button:hover,
  .button button:hover {
    outline: 6px solid var(--primary-color);
    outline-offset: -5px;
  }
}
