.selection-menu.resize {
  width: 100%;
}

.selection-menu.resize ul {
  background-color: var(--darkest-color);
  color: var(--lightest-color);
  display: flex;
  list-style-type: none;
  width: 100%;
  justify-content: space-evenly;
  padding: 0.5rem;
}

.selection-menu.resize li {
  cursor: pointer;
  background-color: var(--darkest-color);
  position: relative;
  z-index: 2;
  transition: color 0.1s;
}
.selection-menu.resize span {
  font-family: "futura-pt", sans-serif;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  height: 100%;
  font-size: 1.1rem;
}

.selection-menu.resize li:not(.default):hover {
  color: var(--secondary-color);
}

.selection-menu.resize li.selected {
  font-weight: 600;
  display: block;
}

.selection-menu.resize li.default {
  display: none;
  cursor: default;
}

.single-selection-menu.resize svg {
  position: absolute;
  top: 14px;
  left: 90%;
  z-index: 2;
  display: none;
  fill: var(--lightest-color);
  stroke: var(--lightest-color);
  stroke-width: 2.8;
  width: 25px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .selection-menu.resize {
    position: relative;
    width: 350px;
    height: 50px;
    margin: 1rem;
  }
  .selection-menu.resize ul {
    position: absolute;
    height: 100%;
    display: block;
    padding: 0rem;
  }
  .selection-menu.resize li {
    display: none;
    width: 350px;
    height: 50px;
  }
  .selection-menu.resize li.default.selected {
    display: block;
  }
  .single-selection-menu.resize svg {
    display: block;
  }
}

@media (max-width: 1100px) {
  .selection-menu.resize {
    width: 290px;
  }
  .selection-menu.resize li {
    display: none;
    width: 290px;
    height: 50px;
  }
}

.selection-menu.no-resize {
  position: relative;
  width: 350px;
  height: 50px;
  margin: 1rem;
}
.selection-menu.no-resize ul {
  background-color: var(--darkest-color);
  color: var(--lightest-color);
  display: flex;
  list-style-type: none;

  justify-content: space-evenly;
  padding: 0.5rem;
  position: absolute;
  height: 100%;
  display: block;
  padding: 0rem;
}
.selection-menu.no-resize li {
  cursor: pointer;
  background-color: var(--darkest-color);
  position: relative;
  z-index: 2;
  transition: color 0.1s;
  display: none;
  width: 350px;
  height: 50px;
}
.selection-menu.no-resize li.default.selected {
  display: block;
}

.selection-menu.no-resize li.selected {
  font-weight: 600;
  display: block;
}

.selection-menu.no-resize li.default {
  display: none;
  cursor: default;
}

.selection-menu.no-resize span {
  font-family: "futura-pt", sans-serif;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  height: 100%;
  font-size: 1.2rem;
}

.single-selection-menu.no-resize svg {
  display: block;
  z-index: 2;
  position: absolute;
  left: 90%;
  top: 14px;
  fill: var(--lightest-color);
  stroke: var(--lightest-color);
  stroke-width: 2.8;
  width: 25px;

  cursor: pointer;
}

.single-selection-menu {
  z-index: 10;
}

@media (max-width: 500px) {
  .selection-menu.no-resize {
    width: 290px;
  }
  .selection-menu.no-resize li {
    display: none;
    width: 290px;
    height: 50px;
  }
}

.multi-selection-menu .default svg {
  height: 25px;
  stroke-width: 2.8;
}

.multi-selection-menu span {
  display: flex;
  justify-content: space-between;
}
.multi-selection-menu li:not(.default) svg {
  fill: var(--secondary-color);
  height: 30px;
}
