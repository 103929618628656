.crew-member {
  background-color: var(--darkest-color);
  color: var(--lightest-color);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.crew-member .text-section {
  color: var(--lightest-color);
  padding: 0.5rem;
}

.crew-member .button {
  margin: 1rem auto;
}

.crew-member .button a {
  font-size: 1.6rem;
}

.crew-member img {
  width: 60%;
  display: block;
  padding: 1rem;
  margin: auto;
  border-radius: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  filter: grayscale();
}

.crew-member .socials {
  padding: 0rem;
}

.crew-member p {
  color: var(--mid-tone-color);
}
