.page-title {
  position: relative;
  z-index: 0;
}

.page-title img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  display: block;
}

.page-title h1 {
  position: absolute;
  color: var(--lightest-color);
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-family: "futura-pt", sans-serif;
  font-size: 2.5rem;
  background-color: var(--darkest-color-transparent);
  padding: 1.5rem;
  text-align: center;
  border-radius: 0.2rem;
  z-index: 1;
}

@media (max-width: 700px) {
  .page-title h1 {
    width: 100%;
  }
}
