.expertise-selection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.expertise-page {
  position: relative;
}

.brochure {
  padding: 2rem;
}

.brochure .text-section {
  padding: 0;
  padding-bottom: 0.8rem;
}
.brochure .button {
  margin: auto;
}
