@media (max-width: 500px), (max-height: 600px) {
  * {
    font-size: 14px;
  }
}

.App {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh);
}
main {
  flex-grow: 1;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span {
  animation-name: fade-in;
  animation-delay: 0ms;
  animation-duration: 0.3s;
}
img {
  animation-name: fade-in-slow;
  animation-duration: 0.5s;
}

@keyframes fade-in-slow {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
