.equipment .items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.equipment .item {
  flex-basis: 32%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
}

.equipment-specs {
  font-family: "futura-pt", sans-serif;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.equipment img {
  border-radius: 0.2rem;
}

.equipment .items .text-section {
  padding: 0.2rem;
  height: 100%;
}
.equipment .items .text-section p {
  font-size: 1.1rem;

  margin: 0.2rem;
}

.equipment .items .text-section h3 {
  font-family: "futura-pt-condensed", sans-serif;
  font-size: 1.8rem;
}

.equipment .button {
  margin: 0 auto;
}
.equipment .button a {
  font-size: 1.5rem;
}

@media (max-width: 1100px) {
  .equipment .item {
    flex-basis: 50%;
  }
}

@media (max-width: 700px) {
  .equipment .item {
    flex-basis: 100%;
  }
}
