.expertise {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.expertise .text-section {
  max-width: 800px;
  padding-bottom: 0;
}
.expertise .text-section p {
  margin: 0;
}

.expertise .button {
  margin: 1rem auto;
}

.expertise .carousel .images img {
  width: 100%;
  min-height: 500px;
  aspect-ratio: 16 / 9;
}

@media (max-width: 900px) {
  .expertise {
    grid-template-columns: 1fr;
  }
}
