.socials {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  margin: auto;
}

.social svg {
  width: 40px;
  height: 40px;
  fill: var(--lightest-color);
  transition: fill 0.2s;
}

.social svg:hover {
  fill: var(--primary-color);
}
