*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #dc1f2b;
  --secondary-color: #b2b2b2;
  --third-color: #33353f;
  --lightest-color: #fdfdfd;
  --mid-tone-color: #e4e4e4;
  --darkest-color: #161616;
  --darkest-color-transparent: #16161681;
}
